.locale-dropdown {
  cursor: pointer;

  .locale-title {
    margin-left: 0.4rem;
    color: $gray-700;
    font-size: 0.9rem;
  }

  .dropdown-item {
    &.active, &:hover, &:focus {
      .locale-title {
        color: #fff;
      }
    }
  }

  .dropdown-toggle {
    align-items: center;

    &:after {
      color: $gray-700;
      margin-left: 0.4rem;
    }
  }

  .dropdown-menu {
    border-radius: $border-radius-base;

    .dropdown-item {
      padding: 0.25rem 0.7rem;
    }
  }

}
