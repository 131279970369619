.widget-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  align-self: center;

  .image-header-container {
    position: relative;
    min-height: 52px;

    .image-header {
      width: 100%;
      min-height: 52px;
      max-width: 600px;
      max-height: 18vh;
      object-fit: cover;

      @include media-breakpoint-up(md) {
        max-height: 180px;
        border-radius: $border-radius-base;
      }
    }

    .image-header-title {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: black;
      padding: 8px;
      font-weight: 500;
      font-size: 1.5rem;
      box-sizing: border-box;
      min-height: 70px;
      backdrop-filter: blur(1px);
      //background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgb(0 0 0 / 60%) 100%);

      &:not(.missing-image) {
        backdrop-filter: unset;
        color: white;
        text-shadow: 0 2px 10px #000;
      }

      &.missing-image {
        background: $gray-200;
      }

      @include media-breakpoint-up(md) {
        border-radius: $border-radius-base;
      }
    }

  }

  .content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    max-height: 100%;

    @include media-breakpoint-up(md) {
      margin-left: 30px;
      margin-right: 30px;
      border-radius: $border-radius-base;
      box-shadow: 0 0 30px 2px rgb(106 106 106 / 32%);
    }

    .padded-step-container {
      padding: 1.4rem 0 0 0;

      @include media-breakpoint-up(sm) {
        padding-left: 10px;
        padding-right: 10px;
      }

      @include media-breakpoint-up(md) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

}


