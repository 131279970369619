@import "variables.module.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "overrides.scss";
@import "layout";
@import "services";
@import "locale-selection";

p {
  margin-bottom: 0;
}
