
.service-listing {
  margin-bottom: 32px;

  .naming-col {
    .name {
      @include font-size(1.1rem, true);

      .expand-icon {
        @include font-size(1.4rem, true);
      }
    }
  }

  .end-cols {
    display: flex;
    align-items: center;

    & > * {
      margin-left: 0.4rem;
    }

    .price {
      @include font-size(1.4rem, true);
    }

    .reserve-button {
      @include font-size(0.875rem, true);
    }
  }

  .service-description {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    @include media-breakpoint-up(md) {
      padding-right: 96px;
    }

    p {
      margin-bottom: 0.25rem;
    }
  }
}
