// Based on Pulse 4.3.1 from Bootswatch
// https://bootswatch.com/pulse/

// Adapted to match the Mygon Wiget Theme

//
// Color system
//

$white: #fff !default;
$gray-100: #fafafa !default;
$gray-200: #f6f6f6 !default;
$gray-300: #f2f2f2 !default;
$gray-400: #ededed !default;
$gray-500: #cccccc !default;
$gray-600: #999999 !default;
$gray-700: #666666 !default;
$gray-800: #595959 !default;
$gray-900: #333333 !default;
$black: #000 !default;

$green: hsl(144, 67%, 32%) !default;
$lightgreen: #8df7c2 !default;
$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #593196 !default;
$pink: #e83e8c !default;
$red: #fc3939 !default;
$orange: #fd7e14 !default;
$yellow: #efa31d !default;
$teal: #20c997 !default;
$cyan: #009cdc !default;
$gold: #c49f58 !default;

$primary: $green !default;
$secondary: $lightgreen !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-900 !default;
$smart: $gold !default;
$gold-premium: #BF9002 !default;

// Tell webpack to export these variables to JavaScript
:export {
  white: $white;
  gray100: $gray-100;
  gray200: $gray-200;
  gray300: $gray-300;
  gray400: $gray-400;
  gray500: $gray-500;
  gray600: $gray-600;
  gray700: $gray-700;
  gray800: $gray-800;
  gray900: $gray-900;
  black: $black;
  green: $green;
  lightgreen: $lightgreen;
  blue: $blue;
  indigo: $indigo;
  purple: $purple;
  pink: $pink;
  red: $red;
  orange: $orange;
  yellow: $yellow;
  teal: $teal;
  cyan: $cyan;
  gold: $gold;
  primary: $primary;
  secondary: $secondary;
  success: $success;
  info: $info;
  warning: $warning;
  danger: $danger;
  light: $light;
  dark: $dark;
  smart: $smart;
  goldPremium: $gold-premium;
}

// Add our custom theme colors so we get "variants" for Buttons, etc

$theme-colors: (
  "smart": $smart
);

// Options

$enable-rounded: false !default;

// Body

$body-color: $gray-700 !default;

// Links

$link-hover-color: $primary !default;

// Fonts

$enable-responsive-font-sizes: true;
$font-size-base: 0.825rem !default;

// Tables

$table-border-color: rgba(0, 0, 0, 0.05) !default;

// Forms

$input-focus-border-color: $primary !default;

// Dropdowns

$dropdown-link-color: $gray-700 !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $primary !default;

// Navs

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-link-hover-border-color: $primary !default;

// Navbar

$navbar-padding-y: 1.2rem !default;

$navbar-dark-hover-color: rgba($white, 0.9) !default;
$navbar-dark-active-color: rgba($white, 0.9) !default;

$navbar-light-color: rgba($black, 0.4) !default;
$navbar-light-active-color: rgba($black, 0.7) !default;
$navbar-light-disabled-color: rgba($black, 0.2) !default;

// Progress bars

$progress-bg: $gray-300 !default;
$progress-bar-bg: $primary !default;

// List group

$list-group-bg: $gray-900 !default;
$list-group-border-color: transparent !default;

$list-group-hover-bg: lighten($list-group-bg, 10%) !default;
$list-group-active-color: $white !default;
$list-group-active-bg: $list-group-bg !default;

$list-group-disabled-color: lighten($list-group-bg, 30%) !default;

// Make <Container /> elements only stretch up to 540px, on every screen
$container-max-widths: (
  sm: 600px
);
